.user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color:slategray;
  color:whitesmoke;
}

.user-item h2 {
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
}